<script setup lang="ts">
import { IconBtn } from 'webcc-ui-components'
import { sortBy } from 'lodash-es'

const emit = defineEmits<{
  (e: 'click', opened: boolean): void
}>()

const { locales, locale: currentLocale } = useI18n()
const opened = ref(false)

const sortedLocales = computed(() =>
  sortBy(unref(locales) as { code: string; name: string }[], 'code'),
)

function handleClose(open: boolean) {
  opened.value = open
  emit('click', opened.value)
}
</script>

<template>
  <div>
    <IconBtn
      data-id="consent-language-button"
      size="md"
      variant="neutral"
      :is-active="opened"
      :initials="currentLocale"
      @click="handleClose(true)"
    />

    <LazyLanguageModal
      v-if="opened"
      class="max-w-80"
      :title="$t('components.common.language')"
      @click="handleClose(false)"
      @close="handleClose(false)"
    >
      <LanguageButtonList
        auto-focus
        :locales="sortedLocales"
        :locale="currentLocale"
        @click="handleClose(false)"
        @close="handleClose(false)"
      />
    </LazyLanguageModal>
  </div>
</template>
