<script setup lang="ts">
const runtimeConfig = useRuntimeConfig()
const { locale } = useI18n()
const { $tracking } = useNuxtApp()

const oldOwnerPortalLink = computed(() => {
  return (
    runtimeConfig.public.oldOwnerPortal.replace(
      '{i18n.locale}',
      locale.value,
    ) || '#'
  )
})

function goToOldOwnerPortal(evt: MouseEvent) {
  $tracking.trackBackToOldOwnerPortal()

  // Delay for track event
  setTimeout(() => {
    if (evt.shiftKey) {
      return navigateTo(oldOwnerPortalLink.value, {
        external: true,
        open: {
          target: '_blank',
          windowFeatures: {
            width: 500,
            height: 500,
          },
        },
      })
    }

    if (evt.ctrlKey || evt.metaKey) {
      return navigateTo(oldOwnerPortalLink.value, {
        external: true,
        open: { target: '_blank' },
      })
    }

    return navigateTo(oldOwnerPortalLink.value, {
      external: true,
      open: { target: '_self' },
    })
  }, 500)
}
</script>

<template>
  <NuxtLink
    v-bind="$attrs"
    class="link-sm"
    external
    :to="oldOwnerPortalLink"
    data-id="link-to-old-portal"
    @click.prevent="goToOldOwnerPortal"
  >
    {{ $t('components.common.backToOldOwnerPortal') }}
  </NuxtLink>
</template>
