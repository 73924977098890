import type { Locale } from 'date-fns'
import { format, intlFormat, isDate } from 'date-fns'
import { cs, de, enGB, es, fr, hr, it, nl, pl, pt } from 'date-fns/locale'
import { get, isString } from 'lodash-es'
import { useI18n, defineNuxtPlugin } from '#imports'

const LOCALES: Record<string, Locale> = {
  en: enGB,
  de,
  cs,
  es,
  fr,
  hr,
  it,
  nl,
  pl,
  pt,
}

/**
 * Returns the formatted date string in 'yyyy-MM-dd' format.
 * The method uses for query params, keys.
 * @param date
 * @returns {string | *}
 */
function getQueryDate(date: string | Date) {
  const currentDate = isDate(date) ? (date as Date) : new Date(date)
  return format(currentDate, 'yyyy-MM-dd')
}

/**
 * Returns the formatted years string in 'yyyy-yy' of 'yyyy/yy' for less than 2 years range.
 * @param fromDate
 * @param toDate
 * @returns {string | *}
 */
export function formatYearsRange(
  fromDate: Date | string,
  toDate: Date | string,
) {
  const from = new Date(fromDate).getFullYear()
  const to = new Date(toDate).getFullYear()
  if (from === to) return from.toString()
  if (to - from > 1) return `${from}-${to.toString().slice(-2)}`
  return `${from}/${to.toString().slice(-2)}`
}

type IntlFormatOptions = Parameters<typeof intlFormat>[1]

/**
 * * Return the formatted date string in the given format.
 *  * The method uses Intl.DateTimeFormat inside if options is object.
 *  * formatOptions are the same as Intl.DateTimeFormat options
 * @param date
 * @param options
 * @returns {string}
 */
function formatDate(
  this: ReturnType<typeof useI18n>,
  date: string | Date,
  options: string | IntlFormatOptions,
) {
  const currentDate = isDate(date) ? (date as Date) : new Date(date)

  if (isString(options)) {
    return format(currentDate, options, { locale: LOCALES[this.locale.value] })
  }

  const locale = (this.locales.value as { code: string; iso: string }[]).find(
    ({ code }) => code === this.locale.value,
  )
  return intlFormat(currentDate, options, {
    locale:
      (locale?.code === 'en' ? LOCALES.en.code : locale?.code) ??
      LOCALES.en.code,
  })
}

export default defineNuxtPlugin({
  enforce: 'post',
  setup(nuxtApp) {
    const i18n = get(nuxtApp, '$i18n', {})

    return {
      provide: {
        date: {
          formatDate: formatDate.bind(i18n),
          getQueryDate,
          formatYearsRange,
        },
      },
    }
  },
})

interface DateContext {
  formatDate(date: string | Date, options: string | IntlFormatOptions): string

  formatYearsRange(fromDate: Date | string, toDate: Date | string): string

  getQueryDate(date: string | Date): string
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $date: DateContext
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $date: DateContext
  }
}

declare module '#app' {
  interface NuxtApp {
    $date: DateContext
  }
}
