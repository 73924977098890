<script setup lang="ts">
import {
  Button as Btn,
  FormalToggle as Toggle,
  Modal,
} from 'webcc-ui-components'
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'

const props = defineProps<{
  statistics: boolean
  other: boolean
}>()

const emit = defineEmits<{
  (e: 'save-choice', item: { statistics: boolean; other: boolean }): void
  (e: 'close'): void
}>()

const otherChoice = ref(props.other)
const statsChoice = ref(props.statistics)
const modalRef = ref<InstanceType<typeof Modal>>()

const { deactivate, activate } = useFocusTrap(
  computed(() => modalRef.value?.modalRef),
)

function sendChoice(values: { statistics: boolean; other: boolean }) {
  emit('save-choice', { statistics: values.statistics, other: values.other })
}

onMounted(async () => {
  await nextTick()
  activate()
})

onUnmounted(deactivate)
</script>

<template>
  <Modal
    ref="modalRef"
    open
    :title="$t('components.myFooter.cookieSettings')"
    @close="$emit('close')"
  >
    <section class="flex-1-auto">
      <div class="choice mb-6 grid">
        <div class="mb-4 text-sm font-medium leading-4">
          {{ $t('components.consent.functionalCookies') }}
        </div>
        <div
          class="choice-switch justify-self-end whitespace-nowrap text-sm font-medium leading-4 text-thm-active"
        >
          {{ $t('components.consent.alwaysActive') }}
        </div>
        <div
          class="choice-text max-w-2xl pr-12 text-sm font-normal leading-4 text-txt lg:pr-40"
        >
          {{ $t('components.consent.functionalCookiesInfo') }}
        </div>
      </div>
      <div class="choice mb-6 grid">
        <div class="mb-4 text-sm font-medium leading-4">
          {{ $t('components.consent.analyticalCookies') }}
        </div>
        <div class="justify-self-end">
          <Toggle
            v-bind="$props"
            id="statsChoice"
            v-model="statsChoice"
            label=""
          />
        </div>
        <div
          class="choice-text max-w-2xl pr-12 text-sm font-normal leading-4 text-txt lg:pr-40"
        >
          {{ $t('components.consent.analyticalCookiesInfo') }}
        </div>
      </div>
      <div class="choice grid">
        <div class="mb-4 text-sm font-medium leading-4">
          {{ $t('components.consent.marketingCookies') }}
        </div>
        <div class="justify-self-end">
          <Toggle
            v-bind="$props"
            id="otherChoice"
            v-model="otherChoice"
            label=""
          />
        </div>

        <div
          class="choice-text max-w-2xl pr-12 text-sm font-normal leading-4 text-txt lg:pr-40"
        >
          {{ $t('components.consent.marketingCookiesInfo') }}
        </div>
      </div>
    </section>
    <template #footer>
      <div>
        <footer
          class="flex shrink-0 flex-col rounded-b-md bg-white sm:flex-row-reverse sm:justify-start"
        >
          <Btn
            variant="theme"
            class="mb-4 sm:mb-0 sm:ml-4"
            :label="$t('components.consent.acceptAll')"
            @click="sendChoice({ statistics: true, other: true })"
          />
          <Btn
            outlined
            variant="theme"
            :label="$t('components.consent.saveSelection')"
            @click="sendChoice({ statistics: statsChoice, other: otherChoice })"
          />
        </footer>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
* {
  scrollbar-width: thin;
}

.config-wrapper {
  /* TODO: How to do this in tailwind? */
  background-color: rgba(10, 10, 10, 0.625);
}

.config-content {
  /* TODO: How to do this in tailwind? */
  max-height: calc(100vh - 120px);
}

/* TODO: How to do this in tailwind? */
.choice {
  column-gap: 0.875rem;
  grid-template-columns: 4fr 1fr;
  grid-template-areas:
    'title switch'
    'text text';
}

@media (min-width: 768px) {
  .choice {
    column-gap: 1rem;
  }
}

.choice-title {
  grid-area: title;
}

.choice-switch {
  grid-area: switch;
}

.choice-text {
  grid-area: text;
}

/* https://medium.com/front-end-weekly/creating-a-toggle-switch-in-css-2d23e496d035 */
/* TODO: How to do this in tailwind? */
.checkbox {
  display: none;
}

.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
  background-color: #757c81;
  border-radius: 20px;
}

.switch:not(.disabled) {
  cursor: pointer;
}

.switch::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: white;
  top: 2px;
  left: 2px;
  transition: all 0.5s;
}

.checkbox:checked + .switch::after {
  left: 14px;
}

.checkbox:checked + .switch {
  background-color: var(--color-primary, #0096db);
}

.checkbox:checked + .switch.disabled {
  background-color: var(--color-primary, #0096db);
  opacity: 0.4;
}
</style>
