import validate from "/myapp/node_modules/.pnpm/nuxt@3.13.0_eslint@8.56.0_rollup@4.21.1_typescript@5.4.5_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth from "/myapp/src/modules/auth/runtime/middleware/auth.ts";
import manifest_45route_45rule from "/myapp/node_modules/.pnpm/nuxt@3.13.0_eslint@8.56.0_rollup@4.21.1_typescript@5.4.5_vite@5.4.2/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "payments-middleware": () => import("/myapp/src/middleware/payments-middleware.ts")
}