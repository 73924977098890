<script lang="ts" setup>
import { sortBy } from 'lodash-es'
import { IconBtn } from 'webcc-ui-components'

const emit = defineEmits<{
  (e: 'click', opened: boolean): void
}>()

const { locales, locale: currentLocale } = useI18n()
const activeElement = useActiveElement()

const [opened, toggle] = useToggle()

const sortedLocales = computed(() =>
  sortBy(unref(locales) as { code: string; name: string }[], 'code'),
)
const localeName = computed(
  () => locales.value.find((i) => i.code === currentLocale.value)?.name,
)

function toggleLanguage() {
  toggle()
  activeElement.value?.blur()
  emit('click', opened.value)
}
</script>

<template>
  <div :class="{ 'relative z-10': opened }">
    <OnDevices rule="<md">
      <IconBtn
        size="md"
        variant="neutral"
        data-id="language-button-icon"
        :is-active="opened"
        :initials="currentLocale"
        @click="toggleLanguage"
      />

      <LazyLanguageModal
        v-if="opened"
        :title="$t('components.common.language')"
        @click="toggleLanguage"
        @close="toggle"
      >
        <LanguageButtonList
          :locales="sortedLocales"
          :locale="currentLocale"
          @close="toggle"
          @click="toggleLanguage"
        />
      </LazyLanguageModal>
    </OnDevices>

    <OnDevices rule=">=md">
      <LanguageButton
        :locale="currentLocale"
        :locale-name="localeName!"
        @click="toggleLanguage"
      />

      <div v-if="opened">
        <div class="fixed inset-0 z-10 h-screen w-full bg-transparent" />

        <div
          class="absolute z-20 w-full rounded border border-txt-700 bg-white"
        >
          <LanguageButtonList
            :locales="sortedLocales"
            :locale="currentLocale"
            @click="toggleLanguage"
            @close="toggle"
          />
        </div>
      </div>
    </OnDevices>
  </div>
</template>
