<script lang="ts">
import type { SlotsType, VNode } from 'vue'
import { defineComponent } from 'vue'
// import { defineNuxtComponent } from 'nuxt/app';

const RULE_PROP_REGEX = /^(<|>=)(xs|sm|md|lg|xl|2xl)$/
const IN_CLASS_REGEX = /(^|\s)(flex|inline-flex|block|grid)($|\s)/

export default defineComponent({
  name: 'OnDevices',
  slots: Object as SlotsType<{
    default: {}
  }>,
  props: {
    // Rule on which devices to render children components
    // Rule can be only `less than` (<) OR `greater and equal than` (>=)
    // Examples: '<sm', '<2xl', '<lg', '>=md', '>=sm'
    rule: {
      type: String,
      required: true,
      validator(value: string) {
        return RULE_PROP_REGEX.test(value)
      },
    },
    // in browsers that do not support Accept-CH header
    // at first render the width equals 0
    // and as a fallback visibility classes are needed, e.g. `block` or `flex`
    showClass: { type: String, default: '' },
  },
  setup(props, context) {
    const device = useDevice()

    const mode = computed(() => {
      return device.parseRule(props.rule)?.mode
    })

    const breakpoint = computed(() => {
      return device.parseRule(props.rule)?.breakpoint
    })

    function renderBasedOnWidth() {
      const breakpointWidth = breakpoint.value ? device[breakpoint.value] : 0
      const toRender =
        (mode.value === '<' && device.width < breakpointWidth) ||
        (mode.value === '>=' && device.width >= breakpointWidth)
      const vnodes =
        toRender && context.slots.default ? context.slots.default({}) : null
      return vnodes
    }

    function renderPatchingClassAttr() {
      if (!context.slots.default) return null

      const vnodes = context.slots.default({})

      const patchVNodeClass = (vnode: VNode) => {
        if (!vnode.props) {
          vnode.props = { class: '' }
        }
        if (!vnode.props.class) {
          vnode.props.class = ''
        }
        const staticClass: string = vnode.props.class
        const [, , existClass] = IN_CLASS_REGEX.exec(staticClass) || []
        const showClass = props.showClass || existClass || 'block'
        let newClass
        if (mode.value === '<')
          newClass = [!existClass && showClass, `${breakpoint.value}:hidden`]
            .filter(Boolean)
            .join(' ')
        if (mode.value === '>=')
          newClass = `hidden ${breakpoint.value}:${showClass}`
        vnode.props.class = [staticClass, newClass].filter(Boolean).join(' ')
        return vnode
      }

      return vnodes.map(patchVNodeClass)
    }

    return () => {
      return device.width ? renderBasedOnWidth() : renderPatchingClassAttr()
    }
  },
})
</script>
