<script setup lang="ts">
import { Btn } from 'webcc-ui-components'
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'

const emits = defineEmits<{
  (e: 'save-choice', item: { statistics: boolean; other: boolean }): void
  (e: 'open-config'): void
}>()

const { $consent } = useNuxtApp()
const consentBannerRef = ref<HTMLDivElement>()
const acceptRef = ref<InstanceType<typeof Btn>>()

const { deactivate, activate } = useFocusTrap(consentBannerRef)

watch(
  () => $consent.configOpened,
  (isConfigOpened) => (isConfigOpened ? deactivate() : activate()),
)

function sendChoice(values: { statistics: boolean; other: boolean }) {
  emits('save-choice', { statistics: values.statistics, other: values.other })
}

function handleFocusTrapChange(opened: boolean) {
  opened ? deactivate() : activate()
}

onMounted(async () => {
  window.scroll({ top: 0, behavior: 'instant' })
  await nextTick()
  activate()
  await nextTick()
  acceptRef.value?.$el.focus()
})

onUnmounted(deactivate)
</script>

<template>
  <div>
    <div class="fixed inset-0 z-50 bg-bgr-600 opacity-80" />
    <div
      role="dialog"
      aria-labelledby="title"
      aria-describedby="description"
      data-id="consent-banner"
      class="consent-banner consent-safe-area fixed z-50 inset-0 flex flex-col-reverse overflow-y-scroll overflow-x-hidden scroll-smooth"
    >
      <div
        class="relative md:flex-shrink-0 border-bgr-100 border-[10px] sm:px-4.5 md:px-4.5 flex justify-center bg-white p-3.5 md:pt-4 lg:flex-row lg:px-[22px] xl:px-[66px] 2xl:px-0"
      >
        <div
          ref="consentBannerRef"
          class="flex flex-col gap-y-8 lg:gap-y-0 lg:gap-x-6 xl:gap-x-8 2xl:gap-x-16 lg:flex-row xl:max-w-[1120px] 2xl:max-w-[1272px]"
        >
          <div class="flex grow flex-col gap-y-2">
            <div class="flex justify-between items-center">
              <h2 id="title" class="text-base font-medium leading-5">
                {{ $t('components.consent.cookies') }}
              </h2>

              <OnDevices rule="<lg">
                <ConsentLanguageSwitch @click="handleFocusTrapChange" />
              </OnDevices>
            </div>

            <p id="description" class="text-sm font-normal leading-5">
              {{ $t('components.consent.cookiesInfoFirst') }}
            </p>

            <p class="text-sm font-normal leading-5">
              {{ $t('components.consent.cookiesInfoSecond') }}
            </p>
            <p class="text-sm font-normal leading-5">
              {{ $t('components.consent.cookiesInfoThird') }}

              <Btn
                class="px-0 font-normal underline-offset-4"
                variant="link"
                size="sm"
                :label="$t('components.consent.here')"
                @click="$emit('open-config')"
              />.
            </p>

            <p class="text-sm font-normal leading-5">
              <a
                class="link-sm"
                href="https://www.interhome.group/en-gb/privacy-policy"
                target="_blank"
              >
                {{ $t('components.consent.clickHere') }}
              </a>
              {{ $t('components.consent.cookiesInfoFourth') }}
            </p>
          </div>

          <BasicDivider class="self-stretch bg-bgr-300 lg:h-auto lg:w-px" />

          <div
            class="flex w-full flex-col gap-y-4 sm:gap-y-0 sm:gap-x-4 lg:gap-y-4 lg:gap-x-0 self-end sm:flex-row-reverse sm:justify-start lg:max-w-[205px] lg:flex-col lg:justify-end xl:max-w-[263px]"
          >
            <Btn
              ref="acceptRef"
              variant="theme"
              :label="$t('components.consent.acceptAll')"
              @click="sendChoice({ statistics: true, other: true })"
            />

            <Btn
              variant="neutral"
              outlined
              :label="$t('components.consent.decline')"
              @click="sendChoice({ statistics: false, other: false })"
            />

            <div class="flex justify-center">
              <Btn
                class="my-auto font-normal underline-offset-4"
                variant="link"
                :label="$t('components.consent.configureSelection')"
                @click="$emit('open-config')"
                @keyup.enter="$emit('open-config')"
              />
            </div>
          </div>

          <OnDevices rule=">=lg">
            <ConsentLanguageSwitch
              class="absolute right-4 top-4 flex grow items-center justify-end"
              @click="handleFocusTrapChange"
            />
          </OnDevices>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.consent-banner {
  filter: drop-shadow(0px -35px 50px rgba(21, 21, 21, 0.25));
}
</style>
